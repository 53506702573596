import { Langs } from '@/locales';
import {
  BLUESKY,
  CLIENT_EXTERNAL_URL,
  FACEBOOK,
  INSTAGRAM,
  TIKTOK,
  TWITTER,
} from '../consts';

export const getTwitterUrl = (locale: Langs = 'pt-BR') => TWITTER[locale];

export const getFacebookUrl = (locale: Langs = 'pt-BR') => FACEBOOK[locale];

export const getInstagramUrl = (locale: Langs = 'pt-BR') => INSTAGRAM[locale];

export const getTikTokUrl = (locale: Langs = 'pt-BR') => TIKTOK[locale];

export const getBlueskyUrl = (locale: Langs = 'pt-BR') => BLUESKY[locale];

export const getInternationalLink = (locale: Langs = 'pt-BR') =>
  CLIENT_EXTERNAL_URL[locale];
