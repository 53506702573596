import { PropsWithChildren } from 'react';
import Layout from '@/presentation/Layout';

export default function FeedLayout({
  children,
}: PropsWithChildren<Record<string, unknown>>) {
  return (
    <Layout.Root>
      <Layout.Header />
      <Layout.Main>{children}</Layout.Main>
      <Layout.BottomNavigation />
    </Layout.Root>
  );
}
