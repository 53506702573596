const prefix = '@comments';

export const CommentsActionType = {
  GetCommentsV2: `${prefix}/GET_COMMENTS_V2`,
  GetComments: `${prefix}/GET_COMMENTS`,
  GetCommentsReacted: `${prefix}/GET_COMMENTS_REACTED`,
  GetComment: `${prefix}/GET_COMMENT`,
  GetPrivateComments: `${prefix}/GET_PRIVATE_COMMENTS`,
  GetTopComments: `${prefix}/GET_TOP_COMMENTS`,
  PostNewComment: `${prefix}/POST_NEW_COMMENT`,
  PostlikeComment: `${prefix}/POST_LIKE_COMMENT`,
  PostDislikeComment: `${prefix}/POST_DISLIKE_COMMENT`,
  PostReplyComment: `${prefix}/POST_REPLY_COMMENT`,
  PostEditComment: `${prefix}/POST_EDIT_COMMENT`,
  PostDeleteComment: `${prefix}/POST_DELETE_COMMENT`,
  PostReportComment: `${prefix}/POST_REPORT_COMMENT`,
  PostReactComment: `${prefix}/POST_REACT_COMMENT`,
  PostRemoveCommentReact: `${prefix}/POST_REMOVE_COMMENT_REACT`,
  GetCommentsSuggestions: `${prefix}/GET_COMMENTS_SUGGESTIONS`,
};
