import {
  BodyLight,
  Button,
  Colors,
  H2Heavy,
  Icon,
  MediaQuery,
  Theme,
} from '@pelando/components';
import styled from 'styled-components';

export const IconTitle = styled(Icon)`
  color: rgb(${Theme.colors.Brand});
  font-size: 48px;
  margin-bottom: 24px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    margin-bottom: 16px;
  }
`;

export const Title = styled.h3`
  ${H2Heavy}
  margin-bottom: 8px;
`;

export const Subtitle = styled.p`
  ${BodyLight}
  color: rgb(${Colors.Gray});
  margin-bottom: 24px;
  text-align: center;
`;

export const ModalButton = styled(Button)`
  display: block;
  width: 100%;
  margin-top: 16px;
  height: 48px;
`;

export const Container = styled.div`
  text-align: center;
`;
