import { Envelope } from '@pelando/fontawesome/icons';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { Container, IconTitle, ModalButton, Subtitle, Title } from './styles';

export type ConfirmEmailModalProps = {
  onConfirm: () => void;
  onBackToHome: () => void;
  emailHasSent?: boolean;
  customContent?: string;
  hideCancel?: boolean;
};

export function ConfirmEmailModal({
  onConfirm,
  onBackToHome,
  emailHasSent,
  hideCancel,
}: ConfirmEmailModalProps) {
  const { t } = useTranslation('privateMessage');

  const titleText = emailHasSent
    ? t('confirm-email-modal-title-has-sent')
    : t('confirm-email-modal-title');

  const subtitleText = emailHasSent
    ? t('confirm-email-modal-subtitle-has-sent')
    : t('confirm-email-modal-subtitle');

  return (
    <Container>
      <IconTitle icon={Envelope} />
      <Title>{titleText}</Title>
      <Subtitle>{subtitleText}</Subtitle>
      {!emailHasSent && (
        <ModalButton onClick={onConfirm}>
          {t('confirm-email-modal-button-confirm')}
        </ModalButton>
      )}
      {!hideCancel ? (
        <ModalButton onClick={onBackToHome} light={!emailHasSent}>
          {t('confirm-email-modal-button-on-back')}
        </ModalButton>
      ) : undefined}
    </Container>
  );
}
